body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.link-sidebar {
    color: #fff;
    text-decoration: none;
}

.link-sidebar:hover {
    font-weight: bold;
    color: #fff;
}

.pageTitle {
    font-family: Roboto, sans-serif;
    font-size: 1.2rem;
    color: #4F1271;
    margin: 1.2rem 0rem 1.2rem 0rem;
}

td {
    font-size: small;
}

th {
    font-size: small;
    color: #4A4063;
}

.logo {
    color: #FFFFFF;
    font-size: 32px;
    font-weight: 300;
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid #888888;
}

.menubar {
    background-color: #4A4063;
    height: 100vh;
}

.form-group label {
    align-self: center;
    width: 150px;
}

.form-group input[type="text"], .form-group input[type="number"] {
    width: 300px;
}

.form-group select {
    width: 450px;
}

.form-group .form-text {
    align-self: center;
}

.login-form .form-group input[type="text"],
.login-form .form-group input[type="number"] {
    width: 100%;
}

.login-title {
    text-align: center;
    margin-top: 10vw;
    margin-bottom: 5vw;
}

.logout-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    width: auto;
}

.auth-error {
    font-size: 1.2em;
    margin: 1vw 0;
    color: #ff054b;
}

input[type="date"]:focus {
    outline: unset;
    /*border:unset;*/
}

/* Date Range Selector*/
.placeholderWrap {
    display: flex;
}

.placeholderWrap .placeholder {
    border: 1px solid rgb(211, 211, 211);
    padding: 7px 10px;
    border-radius: 5px;
    outline: none;
    color: gray;
    min-width: 200px;
    text-align: left;
    font-size: 0.9em;
}

.placeholderWrap .placeholder:last-child {
    margin-left: 10px;
}

.default-placeholder {
    padding: 8px 12px !important;
}

/* Date Range Selector - End*/

/*Search customization*/

.search-container {
    display: flex;
    justify-content: space-between;
}

.search-wrapper {
    display: flex;
    gap: 1vw;
    margin-bottom: 2vw;
    align-items: flex-start;
    width: 100%
}

.search-input {
    border-radius: 5px;
    outline: none;
    -webkit-appearance: none;
    border: 1px solid #d3d3d3;
    padding: 7px;
}

.search-container.megapuesta-track .search-input {
    width: 15vw;
}

.search-container.princess-track .search-input {
    width: 20vw;
}

.search-container.conversions .search-input{
    width: 25vw;
}

.search-container.conversions .search-dropdown,
.search-container.princess-track .search-dropdown{
    width: 12vw;
}

/*Search customization - End */